// export default class NonMemberItemDetailsModel {
//   public ItemName: string = "";
//   public UnitPrice: string = "";
//   public Quantity: string = "";
//   public GSTRate: string = "";
//   public SAC: string = "";
// }
export default class NonMemberItemDetailsModel {
  public ItemName: string = "";
  public UnitPrice: string = "";
  public Quantity: string = "";
  public GSTRate: string = "";
  public SAC: string = "";
  public gstRates:string[]= [];
}
